import revive_payload_client_IrZIwIrct6 from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.1_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ddo74h4xanmfxggsyfvqeb46xe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PmKhvZcIXK from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.1_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ddo74h4xanmfxggsyfvqeb46xe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mO6cyVNvaj from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.1_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ddo74h4xanmfxggsyfvqeb46xe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6WrY2tqzNy from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.1_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ddo74h4xanmfxggsyfvqeb46xe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_nzR6rVyEMZ from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.1_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ddo74h4xanmfxggsyfvqeb46xe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_s6Uhps1S7g from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.1_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ddo74h4xanmfxggsyfvqeb46xe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_N5JztlE0K6 from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.1_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ddo74h4xanmfxggsyfvqeb46xe/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_mg1tMtLLM4 from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.1_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ddo74h4xanmfxggsyfvqeb46xe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/apps/entradas/.nuxt/components.plugin.mjs";
import prefetch_client_DoHmQLaE86 from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.1_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_ddo74h4xanmfxggsyfvqeb46xe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/apps/entradas/.nuxt/formkitPlugin.mjs";
import plugin_K87bsrgUar from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.7_jiti@2.4.2_ter_ktvrn2qzf2uewqcdqpoc6ud7wy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_lOU6X1yrGb from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@babel+core@7.26.0_@parcel+watcher@2.5.0_@types+node@22.10.7_graph_huah5bzahbhw6ukpim5kh6mfdu/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_AJWQwtokdQ from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.5_rollup@4.31.0_vue-router@4.4.3_vue@3.4.38_typescrip_sa3osev343hky64itgn6zsuipe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_0rgrA0KqAC from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.5_rollup@4.31.0_vue-router@4.4.3_vue@3.4.38_typescrip_sa3osev343hky64itgn6zsuipe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/base/plugins/sentry.client.ts";
import widget_mode_X6BjB4n2GO from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/base/plugins/widget-mode.ts";
import google_publisher_tag_9jDGgApxI5 from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/plugins/google-publisher-tag.ts";
export default [
  revive_payload_client_IrZIwIrct6,
  unhead_PmKhvZcIXK,
  router_mO6cyVNvaj,
  payload_client_6WrY2tqzNy,
  navigation_repaint_client_nzR6rVyEMZ,
  check_outdated_build_client_s6Uhps1S7g,
  view_transitions_client_N5JztlE0K6,
  chunk_reload_client_mg1tMtLLM4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DoHmQLaE86,
  formkitPlugin_pZqjah0RUG,
  plugin_K87bsrgUar,
  plugin_lOU6X1yrGb,
  composition_AJWQwtokdQ,
  i18n_0rgrA0KqAC,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV,
  widget_mode_X6BjB4n2GO,
  google_publisher_tag_9jDGgApxI5
]