import { default as _91_46_46_46pageSlug_9338tMJp0H1XMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activateXIhRvOuDHkMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue?macro=true";
import { default as indexL5AXHUdbxXMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue?macro=true";
import { default as _91securityKey_93a1Yf69M648Meta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newRcbtYDhovOMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue?macro=true";
import { default as watchlist80yQ1yhQnDMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue?macro=true";
import { default as indexfgpajunoxWMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue?macro=true";
import { default as editnCu6D1tqz3Meta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as index4bxJeE9LzmMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addtarjPXbyWXMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as raffles8bEs8id03mMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as faqbm5VVHciAsMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as info4Olp8QPI9XMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as _91showGroupSlug_93Ncqy4ZduVSMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programqUxTnflK8hMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopXOOa7FyKeTMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showName_93dgozMVvjxgMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue?macro=true";
import { default as _91showSlug_93fyoBdPsPGkMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as subscriptionHHr8p8oJm8Meta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue?macro=true";
import { default as vouchersmgH3zNesyxMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93CkzPOQV369Meta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexiESP95U2g3Meta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as _91citySlug_93tgL9mjqHUgMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue?macro=true";
import { default as cinemasiyaRLKGT0gMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue?macro=true";
import { default as cities4DfCc6w4bTMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue?macro=true";
import { default as indexboERZwvbGCMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue?macro=true";
import { default as _91movieSlug_9378o4Yam423Meta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexA4CGypFwngMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93vtpo9KgQbYMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresx3PyrJh76xMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexWtzOpB1OLMMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue?macro=true";
import { default as _91personSlug_93vDQvA41Rc3Meta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsHRl6i2vDPAMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue?macro=true";
import { default as searcht38BlOLcBnMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue?macro=true";
import { default as vouchersS7z89e2jGHMeta } from "/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/de/konto/aktivieren",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/cuenta/activar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/konto",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/cuenta",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/de/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/de/konto/passwort/neu",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/de/konto/merkliste",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/cuenta/marcadores",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/account/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___es",
    path: "/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexfgpajunoxWMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/de/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editnCu6D1tqz3Meta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___de",
    path: "/de/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___es",
    path: "/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index4bxJeE9LzmMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___de",
    path: "/de/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___es",
    path: "/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addtarjPXbyWXMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffle/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___de",
    path: "/de/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___es",
    path: "/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: raffles8bEs8id03mMeta || {},
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/builder/pages/admin/raffles.vue").then(m => m.default || m)
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___de",
    path: "/de/kino/:citySlug/:cinemaSlug/abo",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___es",
    path: "/cine/:citySlug/:cinemaSlug/suscripcion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/subscription",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___de",
    path: "/de/kino/:citySlug/:cinemaSlug/abo",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___es",
    path: "/cine/:citySlug/:cinemaSlug/suscripcion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/subscription",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___de",
    path: "/de/kino/:citySlug/:cinemaSlug/abo",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___es",
    path: "/cine/:citySlug/:cinemaSlug/suscripcion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/subscription",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___de",
    path: "/de/kino/:citySlug/:cinemaSlug/abo",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___es",
    path: "/cine/:citySlug/:cinemaSlug/suscripcion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/subscription",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/de/kino/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "/de/kino/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/film/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellungen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___de",
    path: "/de/kino/:citySlug/:cinemaSlug/vorstellung/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___de",
    path: "/de/kino/:citySlug/:cinemaSlug/abo",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "/de/kino/:citySlug/:cinemaSlug/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/cine/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/cine/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "/cine/:citySlug/:cinemaSlug/informacion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "/cine/:citySlug/:cinemaSlug/sesiones",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___es",
    path: "/cine/:citySlug/:cinemaSlug/evento/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___es",
    path: "/cine/:citySlug/:cinemaSlug/suscripcion",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "/cine/:citySlug/:cinemaSlug/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91citySlug_93tgL9mjqHUgMeta?.name,
    path: "/en/cinema/:citySlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/info",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/movie/:showGroupSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/program",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug-showName___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/show/:showSlug/:showName",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/subscription",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "/en/cinema/:citySlug/:cinemaSlug/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinema-citySlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinema/[citySlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___de",
    path: "/de/kinos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___es",
    path: "/cines",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cinemas.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___de",
    path: "/de/staedte",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___es",
    path: "/ciudades",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/cities.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___de",
    path: "/de/film/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___es",
    path: "/pelicula/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movie/[movieSlug].vue").then(m => m.default || m)
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/de/filme/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/peliculas/generos/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91genreSlug_93vtpo9KgQbYMeta?.name,
    path: "/en/movies/genre/:genreSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genre/[genreSlug]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___de",
    path: "/de/filme/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___es",
    path: "/peliculas/generos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/genres.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___de",
    path: "/de/filme",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___es",
    path: "/peliculas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___de",
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___es",
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___en",
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___de",
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___es",
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___en",
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___de",
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___es",
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___en",
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___de",
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___es",
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___en",
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___de",
    path: "/de/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___es",
    path: "/persona/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "person-personSlug___en",
    path: "/en/person/:personSlug",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/person/[personSlug].vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___de",
    path: "/de/personen",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___es",
    path: "/personas",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/persons.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/buscar",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___de",
    path: "/de/gutscheine",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___es",
    path: "/codigos",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/entradas/live/2025-03-19_11-13-53_460b8bd/layers/portal/pages/vouchers.vue").then(m => m.default || m)
  }
]